<template>
  <div class="responsibly">
    <div class="topimg" style="width: 100vw;height: 100vh;">
    	<h2 class=" wow fadeInUp">
    		<span style="color:#fff;opacity: .7;font-size:14px;"></span>
    		<div>
    			Enjoy TMA® 
    		</div>
    		<div>
				Responsibly
    		</div>
			<p style="opacity: .7;font-size: 14px;">
				“Enjoy TMA® Responsibly” is our commitment to encourage the enjoyment of
			</p>
			<p style="opacity: .7;font-size: 14px;">
				 beer, responsibly and in moderation.
			</p>
    	</h2>
    	<img src="../assets/images/responsiblybg.jpeg" alt="">
    </div>
	
	<div class=" wow fadeInUp txtCon" style="">
		<p style="color:#999;font-weight: bold;font-size: 14px;">
			ENCOURAGING RESPONSIBILITY
		</p>
		<div style='color:#13670b;font-family:system-ui;font-weight:bold;font-size:40px'>
			Actively encouraging a responsible attitude
		</div>
		<p class="" style="color:#999;margin-top:30px;">
			As an industry leader with a global business, TMA® 
			has an active role to play in promoting responsible and moderate 
			consumption. We have made a commitment to make moderate consumption 
			aspirational through the TMA® brand and our ongoing pledge is to spend
			 at least 10% of our marketing budget on Responsible Consumption campaigns.
		</p>
	</div>
	
	<div class="flex story">
		<div class="history history1 wow fadeInUp" style="grid-area:a">
			<router-link to="/products">
				<img src="../assets/images/resbg.png" alt="">
				<div class="cardContnt">
					<span class="cardbg"></span>
					<div class="cardCon">
						<h3 class=" "
							style="color:#ffffffb3;text-transform:uppercase;font-size: 14px;font-weight: bold;">
							OUR PRODUCTS
							</h3>
						<h2 class="">
							TMA® 0.0
						</h2>
						<p class="p" style="">
							Allows you to enjoy a TMA® at any time of day.
						</p>
					</div>
				</div>
			</router-link>
		</div>
		<div class="history wow fadeInUp" style="grid-area:b">
			<router-link to="#">
				<img src="../assets/images/resbg1.png" alt="">
				<div class="cardContnt">
					<span class="cardbg"></span>
					<div class="cardCon">
						<h3 class=" "
							style="color:#ffffffb3;text-transform:uppercase;font-size: 14px;font-weight: bold;">
							CAMPAIGNS
							</h3>
						<h2 class="">
							Riding Is Still Driving
						</h2>
						<p class="p" style="">
							Starring F1 driver Daniel Ricciardo
						</p>
					</div>
				</div>
			</router-link>
		</div>
	</div>
	
	<!--  -->
	<div class="topimg mid" style="width: 100vw;">
		<div class="midcon">
			<div>
				<h2 class=" wow fadeInUp">
					<div>
						What we do to enjoy
					</div>
					<div>
						 responsibly
					</div>
				</h2>
			</div>
		</div>
		<img src="../assets/images/resmbg.jpeg" alt="">
	</div>
	
	<!--  -->
	
	<div class="flex wow fadeInUp" data-wow-delay=".5s" style="padding: 0 4rem; max-width: 120rem;margin-bottom: 60px;margin-top: 60px;">
		<div style="height: 500px;flex: 1;">
			<img style="width: 100%;height: 100%;object-fit: cover;" src="../assets/images/res1.jpeg" alt="">
		</div>
		<div class="" style="flex: 1;margin-left: 30px;">
			<h2 style="font-size: .875rem;color: #999;">
				TMA® HISTORY
			</h2>
			<h2 style='color: #13670b;font-family: "", "Main", "PT Sans", serif;font-size: 2.5rem;margin-bottom: 1.25rem;'>
				A perfectly brewed history.
			</h2>
			<p style="color: #999;font-size: 1.125rem;">
				The history of TMA® is the history of innovation in beer brewing. From 1873 when Gerard TMA set out to create the ultimate premium lager beer, to present day under the watchful eye of master brewer Willem Van Waesberghe. TMA’s® iconic taste comes from its simplicity, quality and dedication to premium malt ingredients: the perfect malted barley, hop extract and our own special TMA A-Yeast®.
			</p>
		</div>
		
	</div>
	
	<div class="flex wow fadeInUp" data-wow-delay=".5s" style="padding: 0 4rem; max-width: 120rem;margin-bottom: 60px;margin-top: 60px;">
		
		<div class="" style="flex: 1;margin-left: 30px;">
			<h2 style="font-size: .875rem;color: #999;">
				ENJOY TMA® RESPONSIBLY
			</h2>
			<h2 style='color: #13670b;font-family: "", "Main", "PT Sans", serif;font-size: 2.5rem;margin-bottom: 1.25rem;'>
				Responsible consumption at all our events
			</h2>
			<p style="color: #999;font-size: 1.125rem;">
				We partner with the world’s most exciting sporting and music events, 
				and we use the reach of our global sponsorships with UEFA, Formula 1 and Formula E to speak to
				 millions of consumers around the world with our moderation message.
			</p>
			<div class="btn">
				View Sponsorships
			</div>
		</div>
		
		<div style="height: 100%;height: 500px;flex: 1;">
			<img style="width: 100%;height: 100%;object-fit: cover;" src="../assets/images/res2.jpeg" alt="">
		</div>
		
	</div>
	
	<!--  -->
	<div class=" wow fadeInUp" style="padding: 0 4rem;margin:60px auto;width: 1200px;">
		
		<div style='color:#13670b;font-family:system-ui;font-weight:bold;font-size:40px'>
			One of the biggest reasons for drunk driving? 
		</div>
		<div style='color:#13670b;font-family:system-ui;font-weight:bold;font-size:40px;margin-bottom: 1.25rem;'>
			Overconfidence!
		</div>
		<p style="color:#999;font-weight: bold;font-size: 14px;">
			It's when you feel like a great driver, you shouldn't drive.
		</p>
		<p class="" style="color:#999;margin-top:30px">
			TMA® is proud to launch its new ‘When You Drive, Never Drink’ campaign to 
			address the issue of overconfidence when drinking alcohol. With the help of McLaren 
			F1 Driver Daniel Ricciardo and  we want to empower consumers to 
			make the right choice to never drive if they’ve had anything to drink.
		</p>
	</div>
	<!--  -->
	<div class="midBanner">
		<resmid></resmid>
	</div>
	<!--  -->
	
	
  </div>
</template>

<script>
// @ is an alias to /src
import resmid from '@/components/resmid.vue'
export default {
  name: 'responsibly',
  components: {
	  resmid
  }
}
</script>
<style scoped>
	.topimg {
		position: relative;
	}
	
	.topimg::before {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		top: 0;
		content: "";
		background: transparent linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 50%, #000000 100%) 0% 0% no-repeat padding-box;
		opacity: .4;
		z-index: 1;
	}
	
	.topimg h2 {
		overflow: hidden;
		bottom:10vh;
		position: absolute;
		font-size: 50px;
		color:white;
		left:20vw;
		white-space: nowrap;
		z-index: 100;
	}
	
	.topimg img {
		width: 100%;
		height: 100%;
	}
	.txtCon{
		margin:60px auto;
		/* width: 1200px; */
		padding: 0 4rem;
	}
	.history {
		/* flex: 1; */
		position: relative;
		overflow: hidden;
		width: 100%;
		/* height: 100%; */
		padding-top: 56.25%;
		cursor: pointer;
	}
	
	.history::after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: transparent linear-gradient(0deg, #000 0%, rgba(0, 0, 0, .4) 100%) 0% 0% no-repeat padding-box;
		opacity: .4;
		transition: opacity .3s ease;
	}
	.history1{
		background: #000;
	}
	
	.history img {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		width: 100%;
		height: 100%;
		object-position: center;
		object-fit: cover;
		transform: translate3d(0px, 1px, 0px) scale(1.15);
		will-change: transform;
		transition: transform 0.8s cubic-bezier(0, 0, 0, 1) 0s;
	}
	
	.history:hover::after {
		opacity: 0;
	}
	
	.history:hover img {
		transform: translate3d(0px, 1px, 0px) scale(1.25);
	}
	
	.story {
		grid-gap: 1.25rem;
		grid-template: "a b"1fr/1fr 1fr;
		display: grid;
		overflow: hidden;
		padding: 2.4rem;
		margin-bottom: 60rpx;
	}
	
	.cardContnt {
		position: absolute;
		left: 30px;
		bottom: 30px;
		width: 30rem;
		height: auto;
		display: flex;
		align-items: flex-end;
		justify-content: flex-start;
		color: white;
		font-weight: bold;
		z-index: 10;
		font-family: "", "Main", "PT Sans", serif;
	}
	
	.cardbg {
		background: transparent radial-gradient(closest-side at 50% 50%, #51a025 0%, #13670b 71%, #005d1f 100%) 0% 0% no-repeat padding-box;
		opacity: .7;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		top: 0;
		width: 80%;
		padding: 20px 32px;
	}
	
	.cardCon {
		display: flex;
		flex-direction: column;
		position: relative;
		width: 80%;
		padding: 20px 32px;
		transition: all .3s;
	}
	
	.p {
		height: 0;
		opacity: 0;
		overflow: hidden;
		transition: all .3s;
		font-size: 12px;
		color: #ffffffb3;
	
	}
	
	.history:hover .p {
		transition: all .3s;
		height: 40px;
		opacity: 1;
	}
	.mid h2{
		position: unset;
	}
	.mid{
		height: 380px;
		margin-top: 60px;
		margin-bottom: 5rem;
	}
	.mid .midcon{
		display: flex;
		align-items: center;
		overflow: hidden;
		bottom:0;
		top: 0;
		left: 20vw;
		margin: auto;
		position: absolute;
		font-size: 50px;
		color:white;
		white-space: nowrap;
		z-index: 100;
	}
	.mid .btn{
		font-size: 16px; 
	}
	.mid img{
		object-fit: cover;
	}
	.btn {
		color: white;
		background: #13670b;
		width: max-content;
		padding: 15px 40px;
		font-weight: 700;
		cursor: pointer;
		border-radius: 2px;
		margin-top: 20px;
	}
	
	.btn:hover {
		background: #51a025;
		color: white;
	}
	
	
	.midBanner {
		padding: 20px 4rem;
		padding-top: 50px;
		overflow: hidden;
	}
	
	@media (min-width: 80rem) {
		.txtCon{
			padding: 0 16rem;
		}
		.story{
			padding: 0 4rem;
		}
	}
	.txtCon{
		
	}
</style>
