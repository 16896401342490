<template>
	<div class="Abouthome">
		<div style="height: 120px;"></div>
		<div style="max-width: 1200px;margin: auto;color: white;padding: 20px;">
			<video style="width: 100%;" controls src="../assets/activity.mp4" ></video>
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	export default {
		name: 'activity',
		components: {}
	}
</script>
<style scoped>
	.Abouthome {
		min-height: calc(100vh - 300px);
		background: rgb(13, 56, 32);
	}
</style>
