import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../views/index.vue'
// import story from '../views/story.vue'
import sponsorships from '../views/sponsorships.vue'
import products from '../views/products.vue'
import responsibly from '../views/responsibly.vue'
// import history from '../views/history.vue'
import policy from '../views/policy.vue'
import beer from '../views/beer.vue'
import dbeer from '../views/dbeer.vue'
import wine from '../views/wine.vue'
import shede from '../views/shede.vue'
import activity from '../views/activity.vue'
import swallow from '../views/swallow.vue'





import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: index
  },
  // {
  //   path: '/story',
  //   name: 'story',
  //   component: story
  // },
  {
    path: '/beer',
    name: 'beer',
    component: beer
  },
  {
    path: '/dbeer',
    name: 'dbeer',
    component: dbeer
  },
  {
    path: '/shede',
    name: 'shede',
    component: wine
  },
  {
    path: '/tunzhihu',
    name: 'tunzhihu',
    component: shede
  },
  // {
  //   path: '/tunzhihu',
  //   name: 'tunzhihu',
  //   component: swallow
  // },
  {
    path: '/activity',
    name: 'activity',
    component: activity
  },
  
  // {
  //   path: '/sponsorships',
  //   name: 'sponsorships',
  //   component: sponsorships
  // },
  {
    path: '/products',
    name: 'products',
    component: products
  },
  // {
  //   path: '/responsibly',
  //   name: 'responsibly',
  //   component: responsibly
  // },
  // {
  //   path: '/history',
  //   name: 'history',
  //   component: history
  // },
  // {
  //   path: '/policy',
  //   name: 'policy',
  //   component: policy
  // },
  
  
  
  
  
  {
    path: '/HomeView',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/about.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
