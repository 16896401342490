<template>
	<div class="beerHome">
		<div style="height: 90px;"></div>
		<!-- <div style="font-size: 0;">
			<img src="../assets/wine/4.jpeg" alt="">
		</div> -->
		<div class="container" >
			<div class="wow fadeInUp center" style="font-size: 30px;width: 100%;margin-top: 30px;">
				品味·舍得
				<p style="font-size: 18px;">浓香带陈，香如芝兰，丰满细腻，绵甜爽口</p>
				<p style="font-size: 20px;padding: 10px 0;">
					Shede is a distinguished Chinese liquor brand renowned for its exceptional quality and rich heritage. Originating from the historic Shede distillery, which has been crafting fine spirits for centuries, Shede embodies the essence of traditional Chinese craftsmanship and modern innovation.
					
					Shede is meticulously produced using premium ingredients and time-honored techniques, ensuring a smooth, flavorful, and aromatic experience with every sip. Each bottle of Shede reflects the brand's dedication to perfection, combining the wisdom of ancient distillation methods with contemporary advancements in production technology.
				</p>
			</div>
			<div style="margin-top: 30px;font-size: 0;">
				<div class="wow fadeInUp">
					<img style="width: 100%;" src="../assets/wine/9.jpeg" alt="">
				</div>
			</div>
			<div>
				<div class="center">
					<!-- <img class="wow fadeInUp" src="../assets/wine/t1.png" alt=""> -->
				</div>
				
				<div class="flex" style="justify-content: center;font-size: 0;">
					<div class="wow fadeInUp" style="text-align: center;">
						<!-- <img style="width: 500px;" src="../assets/wine/1.png" alt=""> -->
						<img style="width: 100%;" src="../assets/wine/1.jpeg" alt="">
					</div>
					
				</div>
				<div class="flex" style="justify-content: center;font-size: 0;">
					<div class="wow fadeInUp" style="text-align: center;">
						<!-- <img style="width: 500px;" src="../assets/wine/1.png" alt=""> -->
						<img style="width: 100%;" src="../assets/wine/13.jpeg" alt="">
					</div>
				</div>
				<div class="flex" style="justify-content: center;font-size: 0;">
					<div class="wow fadeInUp" style="text-align: center;">
						<!-- <img style="width: 500px;" src="../assets/wine/1.png" alt=""> -->
						<img style="width: 100%;" src="../assets/wine/14.jpeg" alt="">
					</div>
					
				</div>
			</div>
			<div>
				<div class="center">
					<!-- <img class="wow fadeInUp" src="../assets/wine/t1.png" alt=""> -->
				</div>
				<div class="flex">
					
					<div class="wow fadeInUp" style="font-size: 20px;width: 50%;">
						
					</div>
				</div>
			</div>
			<div style="font-size: 0;text-align: center;">
				<!-- <img src="../assets/wine/detail.jpeg" alt=""> -->
			</div>
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	export default {
		name: 'beer',
		components: {}
	}
</script>
<style scoped>
	.beerHome {
		background: url(../assets/wine/bg.jpeg) top center;
	}

	.container {
		padding: 50px 0;
		width: 85%;
		max-width: 1400px;
		margin: auto;
	}

	.center {
		text-align: center;
	}

	.numr img {
		margin-left: -40px;
		margin-right: 20px;
	}

	.numl img {
		margin-right: -40px;
		margin-left: 20px;
	}
</style>
