<template>
  <div class="sponsorships">
	  <div style="height: 100px;"></div>
	  <div class=" wow fadeInUp" style="padding: 0 4rem;margin:60px auto;width: 1200px;">
		  <p style="color:#999;font-weight: bold;font-size: 14px;">SPONSORSHIPS</p>
	  	<div style='color:#13670b;font-family:system-ui;font-weight:bold;font-size:40px'>
	  		World famous beer meets world famous sponsorships.
	  	</div>
	  	<p class="" style="color:#999;margin-top:30px">Nothing pairs with music, sports or the movies like an ice-cold TMA. Explore our sponsorships below:</p>
	  </div>
	  
	  <div class="flex story">
	  	<div class="history wow fadeInUp" style="grid-area:a">
	  		<router-link to="history">
	  			<img src="../assets/images/card.jpeg" alt="">
	  			<div class="cardContnt">
	  				<span class="cardbg"></span>
	  				<div class="cardCon">
	  					<h3 class=" "
	  						style="color:#ffffffb3;text-transform:uppercase;font-size: 14px;font-weight: bold;">
	  						Sponsorships </h3>
	  					<h2 class="">Formula 1 | More than a race</h2>
	  					<p class="p" style="">
							Not all Sundays are the same. If you are a F1 fan, you know that some of them have a special meaning.
						</p>
	  				</div>
	  			</div>
	  		</router-link>
	  	</div>
	  	<!-- <div class="history wow fadeInUp" style="grid-area:b;opacity: 0;">
	  		<router-link to="history">
	  			<img src="../assets/images/img4.jpg" alt="">
	  			<div class="cardContnt">
	  				<span class="cardbg"></span>
	  				<div class="cardCon">
	  					<h3 class=" "
	  						style="color:#ffffffb3;text-transform:uppercase;font-size: 14px;font-weight: bold;">
	  						Sustainability</h3>
	  					<h2 class="">The best beer is brewed in a better world</h2>
	  					<p class="p" style="">
	  						Business has a big role to play in being a positive force for change. For us, this
	  						is what being a sustainable company is all about.
	  					</p>
	  				</div>
	  			</div>
	  		</router-link>
	  	</div> -->
	  </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'sponsorships',
  components: {
    
  }
}
</script>
<style scoped>
	.history {
		/* flex: 1; */
		position: relative;
		overflow: hidden;
		width: 100%;
		/* height: 100%; */
		padding-top: 56.25%;
		cursor: pointer;
	}
	
	.history::after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: transparent linear-gradient(0deg, #000 0%, rgba(0, 0, 0, .4) 100%) 0% 0% no-repeat padding-box;
		opacity: .4;
		transition: opacity .3s ease;
	}
	
	.history img {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		width: 100%;
		height: 100%;
		object-position: center;
		object-fit: cover;
		transform: translate3d(0px, 1px, 0px) scale(1.15);
		will-change: transform;
		transition: transform 0.8s cubic-bezier(0, 0, 0, 1) 0s;
	}
	
	.history:hover::after {
		opacity: 0;
	}
	
	.history:hover img {
		transform: translate3d(0px, 1px, 0px) scale(1.25);
	}
	.story {
		width: 1200px;
		margin: auto;
		grid-gap: 1.25rem;
		grid-template: "a b"1fr/1fr 1fr;
		display: grid;
		overflow: hidden;
		padding-left: 4rem;
		margin-bottom: 60px;
	}
	.cardContnt {
		position: absolute;
		left: 30px;
		bottom: 30px;
		width: 30rem;
		height: auto;
		display: flex;
		align-items: flex-end;
		justify-content: flex-start;
		color: white;
		font-weight: bold;
		z-index: 10;
		font-family: "", "Main", "PT Sans", serif;
	}
	
	.cardbg {
		background: transparent radial-gradient(closest-side at 50% 50%, #51a025 0%, #13670b 71%, #005d1f 100%) 0% 0% no-repeat padding-box;
		opacity: .7;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		top: 0;
		width: 80%;
		padding: 20px 32px;
	}
	
	.cardCon {
		display: flex;
		flex-direction: column;
		position: relative;
		width: 80%;
		padding: 20px 32px;
		transition: all .3s;
	}
	.p {
		height: 0;
		opacity: 0;
		overflow: hidden;
		transition: all .3s;
		font-size: 12px;
		color: #ffffffb3;
	
	}
	
	.history:hover .p {
		transition: all .3s;
		height: 40px;
		opacity: 1;
	}
	
</style>
