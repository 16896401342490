<template>
	<div class="pubFooter">
		<div class="footerWrap">
			<!-- <div class="container">
				<nav>
					<ul>
						<li>
							<router-link to="/story">
								Our story
							</router-link>
						</li>
						<li>
							<router-link to="/products">
								Our products
							</router-link>
						</li>
						<li>
							<router-link to="/responsibly">
								Enjoy responsibly
							</router-link>
						</li>
						<li>
							<router-link to="/sponsorships">
								Sponsorships
							</router-link>
						</li>
						
						<li>
							<router-link to="">
								Terms Of Use
							</router-link>
						</li>
					</ul>
					<ul>
						<li>
							<router-link to="/policy">
								Privacy Policy
							</router-link>
						</li>
						<li>
							<router-link to="">
								Do Not Sell My Personal Information
							</router-link>
						</li>
						<li>
							<router-link to="">
								FAQ
							</router-link>
						</li>
						<li>
							<router-link to="">
								Contact Us
							</router-link>
						</li>
					</ul>
					<div style="flex: 1;display: flex;justify-content: space-between;">
						<div style="flex: 1;">
							<ul>
								<li>
									Socials
								</li>
							</ul>
							<ul class="links">
								
								<li>
									<a href="https://www.facebook.com/TMA" target="_blank" rel="noopener noreferrer"
										title="Facebook" class="footer__social-icon"
										style="">
										<img style="width: 21px;height: 21px;" src="../assets/images/facebook.svg" alt="">
									</a>
								</li>
								<li>
									<a href="https://twitter.com/TMA" target="_blank" rel="noopener noreferrer"
										title="Twitter" class="footer__social-icon"
										style="">
										<img style="width: 21px;height: 21px;" src="../assets/images/twitter.svg" alt="">
									</a>
								</li>
								<li>
									<a href="https://www.instagram.com/TMA/" target="_blank" rel="noopener noreferrer"
										title="instagram" class="footer__social-icon"
										style="">
										<img style="width: 21px;height: 21px;" src="../assets/images/instagram.svg" alt="">
									</a>
								</li>
								
								<li>
									<a href="https://www.youtube.com/user/TMA" target="_blank" rel="noopener noreferrer"
										title="Youtube" class="footer__social-icon"
										style="">
										<img style="width: 21px;height: 21px;" src="../assets/images/youtube.svg" alt="">
									</a>
								</li>
							</ul>
						</div>
						<ul style="flex: unset;">
							<li>
								<router-link to="">
									<img style="width: 32px;height: 32px;" src="../assets/images/responsibly.svg" alt="">
								</router-link>
							</li>
						</ul>
					</div>
					
				</nav>
			</div> -->
			<div style="margin: auto;text-align: center;">
				<img style="width: 84px;height: 50px;margin: auto;" src="../assets/logo.png" alt="">
			</div>
			<div>
				<span
					style="box-sizing: border-box;color: #ffffff;text-align: center;width: 100%;font-family: PT Sans,sans-serif;font-size: .875rem;padding: 40px;background-color: #005d1f;display: block;">
					<!-- Enjoy
					TMA® Responsibly. ©2022 TMA®, Imported by TMA USA, White Plains, NY. Share with 21+ only. TMA®
					products for the USA are brewed and packaged by TMA Brouwerijen B.V. Amsterdam, Holland.
					<br><br>TMA® Original 24oz. cans for the USA are brewed and canned by TMA Browerijen B.V. Amsterdam,
					Holland and Cervecería Cuauhtémoc Moctezuma Monterrey, NL., Mexico. <br><br> TMA® is a registered
					trademark of TMA Brouwerijen B.V. All rights reserved. -->
					TMA Liquor Inc.
					<br />
					公司简介
					<br />
					TMA Liquor Inc 是一家专门从事酒类批发的公司，总部位于美国加州洛杉矶。<br />
					我们的公司致力于为客户提供高质量的酒类产品，并确保以
					最优惠的价格交付给他们。我们的产品范围广泛，包括葡萄酒、啤酒、烈酒、气泡酒等。<br />
					我们与中国及欧美许多知名品牌建立了长期稳定的合作关系，能够为客户提供各类优质酒类产品。<br />
					我们的团队由经验丰富的酒类专业人士组成，他们了解行业动态和市场趋势，并能够提供专
					业的建议和指导，帮助客户选择最适合他们的酒类产品。<br />
					我们的目标是成为酒类批发行业的领导者，通过我们卓越的产品和服务质量，为客 户创造最大的价值。
				</span>
			</div>
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	export default {
		name: 'pubfooter',
		components: {}
	}
</script>
<style scoped>
	.pubFooter {
		background: #13670b;
		color: white;
		position: relative;
		overflow: hidden;
	}

	.pubFooter::before {
		background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, .39) 100%);
		bottom: 0;
		content: "";
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
		pointer-events: none;
		z-index: 1;
	}

	.footerWrap {
		/* display: flex; */
		flex-wrap: wrap;
		margin: 0 auto;
		max-width: 120rem;
		padding-top: 3.75rem;
		position: relative;
		z-index: 10;
	}

	.container {
		padding: 0 4rem;
		width: 100%;
		box-sizing: border-box;
	}

	nav {
		display: flex;
		font-size: 14px;
	}

	nav ul {
		flex: 1;
	}

	nav ul li {
		margin-bottom: .875rem;
	}

	nav ul li a {
		opacity: .9;
	}

	nav ul li:hover {
		text-decoration: underline;
	}

	nav ul li:hover a {
		opacity: 1;
	}

	.links img {
		opacity: .4;
	}

	.links {
		display: flex;
	}

	.footerWrap::before {
		background: transparent linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .75) 100%) 0% 0% no-repeat padding-box;
		bottom: 0;
		content: "";
		left: 0;
		opacity: .8;
		position: absolute;
		right: 0;
		top: 30%;
		pointer-events: none;
	}
</style>
