<template>
	<div class="policy">
		<div style="height: 90px;"></div>
		<div style="padding: 50px 0;">
			<div style="font-weight: bold;font-size: 12px;">
				<router-link to="/">
					<span style="color: #666;">
						HOME
					</span>
				</router-link>
				>
				<span style="color: #666;">
					PRIVACY POLICY
				</span>
			</div>
			<h1 style="font-family: 'tmaliquorinc';color: #205527;font-size: 4.4rem;">Privacy policy</h1>
		</div>
		<div class="col-md-offset-3 col-md-6 col-sm-8 col-sm-offset-4 lazy" style="opacity: 1;">


			<h4>LAST UPDATED: July 23, 2021</h4>
			<p>TMA USA Incorporated (“TMA,” ” we” or “us“) wants you to know that we respect your privacy and
				recognize your desire to safeguard your personal information. This Privacy Policy describes our
				practices in connection with information that we collect through websites, software, social media sites
				and applications controlled by TMA, as well as through HTML-formatted email messages that we send
				to you that link to this Privacy Policy (collectively, the “Services“). The section below entitled
				“California Privacy Rights” describes our practices with respect to the personal information we collect,
				online and offline, from California residents. If you are a California resident and our past, current,
				or prospective employee or contractor or an emergency contact or benefits beneficiary of any such
				employee or contractor, please click <a
					href="" tabindex="0"
					role="link">here</a> for our privacy notice applicable to you.</p>
			<p>The Services are intended for people who are 21 years of age or older. If someone under the legal
				drinking age has provided TMA<sup>®</sup> with personal information by obtaining access to the
				Services in violation of our access restrictions, we request that the parent or legal guardian of that
				person contact TMA<sup>®</sup> so we can remove the information from our records.</p>
			<h4>Personal Information</h4>
			<h4>Personal Information We May Collect</h4>
			<p>“Personal Information” is information that identifies you as an individual or relates to an identifiable
				person, including, for example, your:</p>
			<ul>
				<li>Name</li>
				<li>Email address</li>
				<li>Postal address</li>
				<li>Telephone Number</li>
				<li>Credit and debit card number</li>
				<li>Social media account ID</li>
			</ul>
			<h4>How We May Collect Personal Information</h4>
			<p>We may collect Personal Information in a variety of ways, including:</p>
			<ul>
				<li><strong>Through the Services:</strong>  We may collect Personal Information through the Services,
					such as when you enter our website via our age gate.</li>
				<li><strong>From Other Sources:</strong> We may receive your Personal Information from other online and
					offline sources, such as public databases, joint marketing partners, social media platforms
					(including from people with whom you are friends or otherwise connected) and from other third
					parties. For example, if you elect to connect your social media account to your website account,
					certain Personal Information from your social media account will be shared with us, which may
					include Personal Information that is part of your profile or your friends’ profiles.</li>
			</ul>
			<h4>How We May Use Personal Information</h4>
			<p>We may use Personal Information:</p>
			<ul>
				<li>To respond to your inquiries, fulfill your requests and process your transactions, and to provide
					you with related customer service.</li>
				<li>To send administrative information to you, such as information regarding the Services and changes to
					our terms, conditions and policies.</li>
				<li>To send you marketing communications that we believe may be of interest to you.</li>
				<li>To personalize your experience on the Services by presenting products and offers tailored to you.
				</li>
				<li>To allow you to participate in sweepstakes, contests and similar promotions and to administer these
					activities. Some of these activities have additional rules, which could contain additional
					information about how we use and disclose your Personal Information. We suggest that you read any
					such rules carefully.</li>
				<li>To facilitate social sharing functionality.</li>
				<li>For our business purposes, such as data analysis, audits, fraud monitoring and prevention,
					developing new products, enhancing, improving or modifying our Services, identifying usage trends,
					determining the effectiveness of our promotional campaigns and operating and expanding our business
					activities.</li>
			</ul>
			<h4>How Personal Information May Be Disclosed</h4>
			<p>Your Personal Information may be disclosed:</p>
			<ul>
				<li>To our affiliates, for the purposes described in this Privacy Policy.</li>
				<li>To our third party service providers who provide services such as website hosting, data management
					and analysis, order fulfillment, information technology and related infrastructure provision,
					customer service, email delivery, auditing and other services.</li>
				<li>To third parties that co-sponsor or co-brand activities with TMA<sup>®</sup>, including to
					permit them to send you marketing communications.</li>
				<li>To third-party sponsors and administrators of sweepstakes, contests and similar promotions.</li>
				<li>By you, on message boards, chat, profile pages and blogs and other services to which you are able to
					post information and materials that are visible to other users and/or the general public (“Public
					Postings”) via the Services. Please note that any information you post or disclose via Public
					Postings will become public information. We urge you to be very careful when deciding to publicly
					disclose any information on the Services.</li>
				<li>To your friends associated with your social media account, to other users of the Services and to
					your social media account provider, in connection with your social sharing activity, such as if you
					connect your social media account to your Services account or log into your Services. account from
					your social media account. By connecting your Services account and your social media account, you
					authorize us to share information with your social media account provider, and you understand that
					the use of the information we share will be governed by the social media site’s privacy policy. If
					you do not want your Personal Information shared with other users or with your social media account
					provider, please do not connect your social media account with your use of the Services and do not
					participate in social sharing on the Services.</li>
				<li>To a third party in the event of any reorganization, merger, sale, joint venture, assignment,
					transfer or other disposition of all or any portion of our business, assets or stock (including in
					connection with any bankruptcy or similar proceedings).</li>
			</ul>
			<h4>Other Uses and Disclosures</h4>
			<p>We also may use and disclose your Personal Information as we believe to be necessary or appropriate: (a)
				under applicable law, which may include laws outside your country of residence; (b) to respond to
				requests from courts, law enforcement agencies, regulatory agencies, and other public and government
				authorities, which may include such authorities outside your country of residence; (c) to enforce our
				terms and conditions; and (d) to protect our rights, privacy, safety or property, and/or that of our
				affiliates, you or others.</p>
			<h4>Other Information</h4>
			<h4>Other Information We May Collect</h4>
			<p><strong>“Other Information”</strong> is any information that does not reveal your specific identity or
				does not directly relate to an individual, such as:</p>
			<ul>
				<li>Browser and device information</li>
				<li>App usage data</li>
				<li>Information collected through cookies, pixel tags, web beacons and other technologies</li>
				<li>IP address</li>
				<li>Location information</li>
				<li>Demographic information and other information provided by you</li>
				<li>Aggregated information</li>
			</ul>
			<p>If we are required to treat Other Information as Personal Information under applicable law, then we may
				use it for the purposes for which we use and disclose Personal Information as detailed in this Policy.
			</p>
			<h4>How We May Collect Other Information</h4>
			<p>We and our third party service providers may collect Other Information in a variety of ways, including:
			</p>
			<ul>
				<li><strong>Through your browser or device:</strong><br>
					Certain information is collected by most browsers or automatically through your device, such as your
					Media Access Control (MAC) address, computer type (Windows or Mac), screen resolution, operating
					system name and version, device manufacturer and model, language, Internet browser type and version
					and the name and version of the Services you are using. We use this information to ensure that the
					Services function properly.</li>
				<li><strong>Through your use of an App:</strong><br>
					When you download and use a TMA App, we and our service providers may track and collect app
					usage data, such as the date and time the app on your device accesses our servers and what
					information and files have been downloaded to the app.</li>
				<li><strong>Using cookies:</strong> Cookies are pieces of information stored directly on the computer
					that you are using. Cookies allow us to collect information such as browser type, time spent on the
					Services, pages visited, language preferences, and other traffic data. We and our service providers
					use the information for security purposes, to facilitate navigation, to display information more
					effectively, to personalize your experience while using the Services and to recognize your computer
					in order to facilitate your use of the Services. We also gather statistical information about use of
					the Services in order to continually improve their design and functionality, understand how they are
					used and assist us with resolving questions regarding them. Cookies further allow us to select which
					of our advertisements or offers are most likely to appeal to you and display them while you are on
					the Services. We may also use cookies or other technologies to track responses to our online
					advertisements.</li>
			</ul>
			<p>If you do not want information collected through the use of cookies, there is a simple procedure in most
				browsers that allows you to automatically decline cookies or be given the choice of declining or
				accepting the transfer to your computer of a particular cookie (or cookies) from a particular site. You
				may also wish to refer to  <a href="http://www.allaboutcookies.org/manage-cookies/index.html"
					rel="noreferrer" target="_blank" tabindex="0" role="link"
					title="Opens in a new window">http://www.allaboutcookies.org/manage- </a><a
					href="http://www.allaboutcookies.org/manage-cookies/index.html" rel="noreferrer" target="_blank"
					tabindex="0" role="link" title="Opens in a new window">cookies/index.html </a>. If, however, you do
				not accept cookies, you may experience some inconvenience in your use of the Services. For example, we
				may not be able to recognize your computer, and you may need to enter your birthday every time you
				visit. You also may not receive advertising or other offers from us that are relevant to your interests
				and needs.</p>
			<ul>
				<li><strong>Using pixel tags and other similar technologies:</strong> Pixel tags (also known as web
					beacons and clear GIFs) may be used in connection with some Services to, among other things, track
					the actions of users of the Services (including email recipients), measure the success of our
					marketing campaigns and compile statistics about usage of the Services and response rates.</li>
				<li><strong>Analytics:</strong>  We use Google Analytics, which uses cookies and similar technologies to
					collect and analyze information about use of the Services and report on activities and trends. This
					service may also collect information regarding the use of other websites, apps and online resources.
					You can learn about Google’s practices by going to <a
						href="http://www.google.com/policies/privacy/partners/" target="_blank" tabindex="0" role="link"
						rel="noreferrer" title="Opens in a new window">www.google.com/policies/privacy/partners/</a> ,
					and opt out of them by downloading the Google Analytics opt-out browser add-on, available at <a
						href="https://tools.google.com/dlpage/gaoptout" target="_blank" tabindex="0" role="link"
						rel="noreferrer" title="Opens in a new window">https://tools.google.com/dlpage/gaoptout</a>.
				</li>
				<li><strong>Using Adobe Flash technology (including Flash Local Shared Objects (“Flash LSOs”)) and other
						similar technologies: </strong> We may use Flash LSOs and other technologies to, among other
					things, collect and store information about your use of the Services. If you do not want Flash LSOs
					stored on your computer, you can adjust the settings of your Flash player to block Flash LSO storage
					using the tools contained in the Website Storage Settings Panel . You can also control Flash LSOs by
					going to the Global Storage Settings Panel and following the instructions (which may include
					instructions that explain, for example, how to delete existing Flash LSOs (referred to as
					“information” on the Macromedia site), how to prevent Flash LSOs from being placed on your computer
					without your being asked, and (for Flash Player 8 and later) how to block Flash LSOs that are not
					being delivered by the operator of the page you are on at the time). Please note that setting the
					Flash Player to restrict or limit acceptance of Flash LSOs may reduce or impede the functionality of
					some Flash applications.</li>
				<li><strong>IP Address: </strong>  Your IP address is a number that is automatically assigned to the
					computer that you are using by your Internet Service Provider. An IP address may be identified and
					logged automatically in our server log files whenever a user accesses the Services, along with the
					time of the visit and the page(s) that were visited. Collecting IP addresses is standard practice
					and is done automatically by many websites, applications and other services. We use IP addresses for
					purposes such as calculating usage levels, diagnosing server problems and administering the
					Services. We may also derive your location from your IP address.</li>
				<li><strong>Location: </strong> We may collect the physical location of your device by, for example,
					using satellite, cell phone tower or WiFi signals. We may use your device’s physical location to
					provide you with personalized location-based services and content.</li>
				<li><strong>From you:</strong> Information, such as your preferences or demographic information is
					collected when you voluntarily provide it.</li>
				<li><strong>By aggregating information:</strong> Aggregated Personal Information does not personally
					identify you or any other user of the Services (for example, we may aggregate Personal Information
					to calculate the percentage of our users who have a particular telephone area code).</li>
			</ul>
			<h4>How We May Use and Disclose Other Information</h4>
			<p>We may use and disclose Other Information for any purpose, except where we are required to do otherwise
				under applicable law. In some instances, we may combine Other Information with Personal Information. If
				we do, we will treat the combined information as Personal Information as long as it is combined.</p>
			<h4>Do Not Track Signals</h4>
			<p>Some web browsers have settings that include “do not track signals.” The Services are not currently
				engineered to respond to those signals.</p>
			<h4>Third Party Services</h4>
			<p>This Privacy Policy does not address, and we are not responsible for, the privacy, information or other
				practices of any third parties, including any third party operating any site or service to which the
				Services link. The inclusion of a link on the Services does not imply endorsement of the linked site or
				service by us or by our affiliates.</p>
			<p>In addition, we are not responsible for the information collection, use, disclosure or security policies
				or practices of other organizations, such as Facebook, Apple, Google, Microsoft, RIM or any other app
				developer, app provider, social media platform provider, operating system provider, wireless service
				provider or device manufacturer, including with respect to any Personal Information you disclose to them
				in connection with the Services.</p>

		</div>
		<div style="height: 50px;"></div>
	</div>
</template>

<script>
	// @ is an alias to /src

	export default {
		name: 'policy',
		components: {

		}
	}
</script>
<style scoped>
	.policy {
		min-height: 100vh;
		width: 900px;
		margin: auto;
	}
</style>
