<template>
	<div id="app" :class="showFooter?'':'hidefooterBg'">
		<!-- <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </nav> -->
		<pubHead></pubHead>
		<router-view />
		<pubfooter v-if="showFooter"></pubfooter>
	</div>
</template>

<script>
	// @ is an alias to /src
	import pubHead from '@/components/pubHead.vue'
	import pubfooter from '@/components/pubfooter.vue'

	export default {
		components: {
			pubHead,
			pubfooter
		},
		data() {
			return{
				showFooter:true
			}
		},
		created() {
			if(this.$route.path=='/about'){
				this.showFooter = false
			}else{
				this.showFooter = true
			}
		},
		watch: {
			$route(a, b) {
				console.log(a)
				if(a.path=='/about'){
					this.showFooter = false
				}else{
					this.showFooter = true
				}
			}
		},
	}
</script>

<style>
	* {
		margin: 0;
		padding: 0;
	}

	a {
		text-decoration: none;
		color: white;
	}

	li {
		list-style: none;
	}

	html,
	body {
		position: relative;
		height: 100%;
		font-family: "", "Main", "PT Sans", serif;
		--primary-font-names: "", "PT Sans", sans-serif;
		--secondary-font-names: "", "Main", "PT Sans", serif;
	}

	body {
		/* background: #eee; */
		font-family: Avenir, Helvetica, Arial, sans-serif;
		font-size: 100%;
		color: #000;
		margin: 0;
		padding: 0;
	}

	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		/* text-align: center; */
	}

	.flex {
		display: flex;
		align-items: center;
	}

	.between {
		justify-content: space-between;
	}
	.hidefooterBg{
		min-height: 100vh;
		background: rgb(13, 56, 32);
	}
</style>
