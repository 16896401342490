var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"responsibly"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"flex story"},[_c('div',{staticClass:"history history1 wow fadeInUp",staticStyle:{"grid-area":"a"}},[_c('router-link',{attrs:{"to":"/products"}},[_c('img',{attrs:{"src":require("../assets/images/resbg.png"),"alt":""}}),_c('div',{staticClass:"cardContnt"},[_c('span',{staticClass:"cardbg"}),_c('div',{staticClass:"cardCon"},[_c('h3',{staticClass:"",staticStyle:{"color":"#ffffffb3","text-transform":"uppercase","font-size":"14px","font-weight":"bold"}},[_vm._v(" OUR PRODUCTS ")]),_c('h2',{},[_vm._v(" TMA® 0.0 ")]),_c('p',{staticClass:"p"},[_vm._v(" Allows you to enjoy a TMA® at any time of day. ")])])])])],1),_c('div',{staticClass:"history wow fadeInUp",staticStyle:{"grid-area":"b"}},[_c('router-link',{attrs:{"to":"#"}},[_c('img',{attrs:{"src":require("../assets/images/resbg1.png"),"alt":""}}),_c('div',{staticClass:"cardContnt"},[_c('span',{staticClass:"cardbg"}),_c('div',{staticClass:"cardCon"},[_c('h3',{staticClass:"",staticStyle:{"color":"#ffffffb3","text-transform":"uppercase","font-size":"14px","font-weight":"bold"}},[_vm._v(" CAMPAIGNS ")]),_c('h2',{},[_vm._v(" Riding Is Still Driving ")]),_c('p',{staticClass:"p"},[_vm._v(" Starring F1 driver Daniel Ricciardo ")])])])])],1)]),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('div',{staticClass:"midBanner"},[_c('resmid')],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"topimg",staticStyle:{"width":"100vw","height":"100vh"}},[_c('h2',{staticClass:"wow fadeInUp"},[_c('span',{staticStyle:{"color":"#fff","opacity":".7","font-size":"14px"}}),_c('div',[_vm._v(" Enjoy TMA® ")]),_c('div',[_vm._v(" Responsibly ")]),_c('p',{staticStyle:{"opacity":".7","font-size":"14px"}},[_vm._v(" “Enjoy TMA® Responsibly” is our commitment to encourage the enjoyment of ")]),_c('p',{staticStyle:{"opacity":".7","font-size":"14px"}},[_vm._v(" beer, responsibly and in moderation. ")])]),_c('img',{attrs:{"src":require("../assets/images/responsiblybg.jpeg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wow fadeInUp txtCon"},[_c('p',{staticStyle:{"color":"#999","font-weight":"bold","font-size":"14px"}},[_vm._v(" ENCOURAGING RESPONSIBILITY ")]),_c('div',{staticStyle:{"color":"#13670b","font-family":"system-ui","font-weight":"bold","font-size":"40px"}},[_vm._v(" Actively encouraging a responsible attitude ")]),_c('p',{staticStyle:{"color":"#999","margin-top":"30px"}},[_vm._v(" As an industry leader with a global business, TMA® has an active role to play in promoting responsible and moderate consumption. We have made a commitment to make moderate consumption aspirational through the TMA® brand and our ongoing pledge is to spend at least 10% of our marketing budget on Responsible Consumption campaigns. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"topimg mid",staticStyle:{"width":"100vw"}},[_c('div',{staticClass:"midcon"},[_c('div',[_c('h2',{staticClass:"wow fadeInUp"},[_c('div',[_vm._v(" What we do to enjoy ")]),_c('div',[_vm._v(" responsibly ")])])])]),_c('img',{attrs:{"src":require("../assets/images/resmbg.jpeg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex wow fadeInUp",staticStyle:{"padding":"0 4rem","max-width":"120rem","margin-bottom":"60px","margin-top":"60px"},attrs:{"data-wow-delay":".5s"}},[_c('div',{staticStyle:{"height":"500px","flex":"1"}},[_c('img',{staticStyle:{"width":"100%","height":"100%","object-fit":"cover"},attrs:{"src":require("../assets/images/res1.jpeg"),"alt":""}})]),_c('div',{staticStyle:{"flex":"1","margin-left":"30px"}},[_c('h2',{staticStyle:{"font-size":".875rem","color":"#999"}},[_vm._v(" TMA® HISTORY ")]),_c('h2',{staticStyle:{"color":"#13670b","font-family":"\"\", \"Main\", \"PT Sans\", serif","font-size":"2.5rem","margin-bottom":"1.25rem"}},[_vm._v(" A perfectly brewed history. ")]),_c('p',{staticStyle:{"color":"#999","font-size":"1.125rem"}},[_vm._v(" The history of TMA® is the history of innovation in beer brewing. From 1873 when Gerard TMA set out to create the ultimate premium lager beer, to present day under the watchful eye of master brewer Willem Van Waesberghe. TMA’s® iconic taste comes from its simplicity, quality and dedication to premium malt ingredients: the perfect malted barley, hop extract and our own special TMA A-Yeast®. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex wow fadeInUp",staticStyle:{"padding":"0 4rem","max-width":"120rem","margin-bottom":"60px","margin-top":"60px"},attrs:{"data-wow-delay":".5s"}},[_c('div',{staticStyle:{"flex":"1","margin-left":"30px"}},[_c('h2',{staticStyle:{"font-size":".875rem","color":"#999"}},[_vm._v(" ENJOY TMA® RESPONSIBLY ")]),_c('h2',{staticStyle:{"color":"#13670b","font-family":"\"\", \"Main\", \"PT Sans\", serif","font-size":"2.5rem","margin-bottom":"1.25rem"}},[_vm._v(" Responsible consumption at all our events ")]),_c('p',{staticStyle:{"color":"#999","font-size":"1.125rem"}},[_vm._v(" We partner with the world’s most exciting sporting and music events, and we use the reach of our global sponsorships with UEFA, Formula 1 and Formula E to speak to millions of consumers around the world with our moderation message. ")]),_c('div',{staticClass:"btn"},[_vm._v(" View Sponsorships ")])]),_c('div',{staticStyle:{"height":"500px","flex":"1"}},[_c('img',{staticStyle:{"width":"100%","height":"100%","object-fit":"cover"},attrs:{"src":require("../assets/images/res2.jpeg"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wow fadeInUp",staticStyle:{"padding":"0 4rem","margin":"60px auto","width":"1200px"}},[_c('div',{staticStyle:{"color":"#13670b","font-family":"system-ui","font-weight":"bold","font-size":"40px"}},[_vm._v(" One of the biggest reasons for drunk driving? ")]),_c('div',{staticStyle:{"color":"#13670b","font-family":"system-ui","font-weight":"bold","font-size":"40px","margin-bottom":"1.25rem"}},[_vm._v(" Overconfidence! ")]),_c('p',{staticStyle:{"color":"#999","font-weight":"bold","font-size":"14px"}},[_vm._v(" It's when you feel like a great driver, you shouldn't drive. ")]),_c('p',{staticStyle:{"color":"#999","margin-top":"30px"}},[_vm._v(" TMA® is proud to launch its new ‘When You Drive, Never Drink’ campaign to address the issue of overconfidence when drinking alcohol. With the help of McLaren F1 Driver Daniel Ricciardo and we want to empower consumers to make the right choice to never drive if they’ve had anything to drink. ")])])
}]

export { render, staticRenderFns }