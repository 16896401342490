<template>
	<div style="position: relative;">
		<div class="swiper-container1">
			<div class="swiper-wrapper">
				<div class="swiper-slide">
					<div class="title" data-swiper-parallax="0">
						<div class="titBox">
							<h4 style="color: #1b4677;font-size: 2.5rem;font-family: '', 'Main', 'PT Sans', serif;">
								TMA® 0.0
							</h4>
							<h2 style="font-size: 1.125rem;color: #999;">
								Brewing a great tasting 0.0% alcohol free malt beverage.
							</h2>
						</div>
					</div>
					<div class="img-box">
						<img src="../assets/images/resbanner1.png" style="transform:translateX(0);">
					</div>
				</div>
				<div class="swiper-slide">
					<div class="title" data-swiper-parallax="-130%">
						<div class="titBox">
							<h4 style="color: #1b4677;font-size: 2.5rem;font-family: '', 'Main', 'PT Sans', serif;">
								TMA® 0.0 Draught
							</h4>
							<h2 style="font-size: 1.125rem;color: #999;">
								No alcohol, 100% TMA®. We are proud of our TMA® 0.0. Brewed with devotion and
								our heritage knowledge of beer.
							</h2>
						</div>
					</div>
					<div class="img-box"><img src="../assets/images/resbanner2.png"></div>
				</div>
				<div class="swiper-slide">
					<div class="title" data-swiper-parallax="-130%">
						<div class="titBox">
							<h4 style="color: #1b4677;font-size: 2.5rem;font-family: '', 'Main', 'PT Sans', serif;">
								TMA® 0.0 Can
							</h4>
							<h2 style="font-size: 1.125rem;color: #999;">
								Feel and enjoy wherever you are a true touch of class with a cold can of TMA 0.0
							</h2>
						</div>
					</div>
					<div class="img-box">
						<img style="margin-top: -50px;" src="../assets/images/resbanner3.png">
					</div>
				</div>


			</div>
			<div class="button-prev button">
				
				<svg t="1677329600872" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2441" width="128" height="128"><path d="M784.652701 955.6957 346.601985 517.644983c-2.822492-2.822492-2.822492-7.902977 0-11.289967l439.179713-439.179713c6.77398-6.77398 10.725469-16.370452 10.725469-25.966924L796.507166 36.692393c0-20.32194-16.370452-36.692393-36.692393-36.692393l-4.515987 0c-9.596472 0-19.192944 3.951488-25.966924 10.725469L250.072767 489.420066c-12.418964 12.418964-12.418964 32.740904 0 45.159868l477.565601 477.565601c7.338479 7.338479 17.499449 11.854465 28.224917 11.854465l0 0c22.015436 0 40.079383-18.063947 40.079383-40.079383l0 0C796.507166 973.759647 791.99118 963.598677 784.652701 955.6957z" p-id="2442"></path></svg>
				<!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 350 160 90">
					<g id="arrow-svg-home">
						<g id="circ" class="cls-1">
							<circle cx="42" cy="42" r="40" class="cls-4"></circle>
						</g>
						<g id="arrow">
							<path id="arrow-trg" d="M.983,6.929,4.447,3.464.983,0,0,.983,2.482,3.464,0,5.946Z"></path>
						</g>
						<path id="line" d="M120,0H0" class="cls-3"></path>
					</g>
				</svg> -->
			</div>
			<!--左箭头-->
			<div class="button-next button"> 
				<svg t="1677331009532" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1355" width="128" height="128"><path d="M239.347299 68.3043L677.398015 506.355017c2.822492 2.822492 2.822492 7.902977 1e-8 11.289967l-439.17971301 439.179713c-6.77398 6.77398-10.725469 16.370452-10.725469 25.966924L227.492834 987.30760699c0 20.32194 16.370452 36.692393 36.692393 36.69239301l4.515987 0c9.596472 0 19.192944-3.951488 25.966924-10.725469L773.927233 534.579934c12.418964-12.418964 12.418964-32.740904 0-45.159868l-477.565601-477.565601c-7.338479-7.338479-17.499449-11.85446499-28.224917-11.854465l0 0c-22.01543601 0-40.079383 18.06394699-40.079383 40.079383l0 0C227.492834 50.240353 232.00882 60.401323 239.347299 68.3043z" p-id="1356"></path></svg>
				<!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 350 160 90">
					<g id="arrow-svg-home">
						<g id="circ" class="cls-1">
							<circle cx="42" cy="42" r="40" class="cls-4"></circle>
						</g>
						<g id="arrow">
							<path id="arrow-trg" d="M.983,6.929,4.447,3.464.983,0,0,.983,2.482,3.464,0,5.946Z"
								class="cls-2"></path>
						</g>
						<path id="line" d="M120,0H0" class="cls-3"></path>
					</g>
				</svg> -->
			</div>
			<!--右箭头-->
		</div>
	</div>
</template>

<script>
	export default {
		name: 'homeBanner',
		data() {
			return {}
		},
		mounted() {
			// setTimeout(() => {
			this.swiperInit()
			// }, 1000)
		},
		methods: {
			swiperInit() {
				var slides = [],
					imgBox,
					imgPrev,
					imgActive,
					derection;
				var lock = false;
				// var bgColor = ["rgb(50 125 182)", "rgb(21 22 21)", "rgb(43 135 9)", "rgb(86 81 74)", "rgb(19 24 24)"]; //背景色
				var bgColor = ["white", "white", "white", "white", "white"]; //背景色
				var mySwiper = new Swiper('.swiper-container1', {
					speed: 1300,
					autoplay: true,
					allowTouchMove: true, //禁止触摸滑动
					parallax: true, //文字位移视差
					on: {
						transitionStart: function() {
							lock = true; //锁住按钮
							slides = this.slides
							imgBox = slides.eq(this.previousIndex).find('.img-box') //图片包装器
							imgPrev = slides.eq(this.previousIndex).find('img') //当前图片
							imgActive = slides.eq(this.activeIndex).find('img') //下一张图片
							derection = this.activeIndex - this.previousIndex
							this.$el.css("background-color", bgColor[this.activeIndex]); //背景颜色动画
							// this.$el.css("background-color", 'none'); //背景颜色动画

							imgBox.transform('matrix(0.6, 0, 0, 0.6, 0, 0)');
							imgPrev.transition(1000).transform('matrix(1.2, 0, 0, 1.2, 0, 0)'); //图片缩放视差
							this.slides.eq(this.previousIndex).find('h3').transition(1000).css('color',
								'rgba(255,255,255,0)'); //文字透明动画

							imgPrev.transitionEnd(function() {
								imgActive.transition(1300).transform(
									'translate3d(0, 0, 0) matrix(1.2, 0, 0, 1.2, 0, 0)'); //图片位移视差
								imgPrev.transition(1300).transform('translate3d(' + 60 * derection +
									'%, 0, 0)  matrix(1.2, 0, 0, 1.2, 0, 0)');
							});
						},
						transitionEnd: function() {
							this.slides.eq(this.activeIndex).find('.img-box').transform(
								' matrix(1, 0, 0, 1, 0, 0)');
							imgActive = this.slides.eq(this.activeIndex).find('img')
							imgActive.transition(1000).transform(' matrix(1, 0, 0, 1, 0, 0)');
							this.slides.eq(this.activeIndex).find('h3').transition(1000).css('color',
								'rgba(255,255,255,1)');

							imgActive.transitionEnd(function() {
								lock = false
							});
							//第一个和最后一个，禁止按钮
							if (this.activeIndex == 0) {
								this.$el.find('.button-prev').addClass('disabled');
							} else {
								this.$el.find('.button-prev').removeClass('disabled');
							}

							if (this.activeIndex == this.slides.length - 1) {
								this.$el.find('.button-next').addClass('disabled');
							} else {
								this.$el.find('.button-next').removeClass('disabled');
							}
						},
						init: function() {
							this.emit('transitionEnd'); //在初始化时触发一次transitionEnd事件
						},

					}
				});

				//不使用自带的按钮组件，使用lock控制按钮锁定时间
				mySwiper.$el.find('.button-next').on('click', function() {
					if (!lock) {
						mySwiper.slideNext();
					}
				})
				mySwiper.$el.find('.button-prev').on('click', function() {
					if (!lock) {
						mySwiper.slidePrev();
					}
				})
			}
		}
	}
</script>

<style scoped>
	.swiper-container1 {
		width: 100%;
		height: 500px;
		background-color: rgb(50 125 182);
		background: white;
		transition: 1s background-color 1.3s;
	}

	.swiper-wrapper {
		transition-delay: 1s;
		transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
	}

	.swiper-slide {
		text-align: center;
		font-size: 18px;
		/* Center slide text vertically */
		display: -webkit-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		display: flex;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		-webkit-justify-content: center;
		justify-content: center;
		-webkit-box-align: center;
		-ms-flex-align: center;
		-webkit-align-items: center;
		align-items: center;
	}

	.title {
		color: white;
		height: 100%;
		justify-content: center;
		/* width: 100%; */
		transition-delay: 1s;
		z-index: 10;
		display: flex;
		align-items: center;
		padding: 0 4.75rem;
		text-align: left;
	}

	.titBox {
		width: 400px;
	}

	.title h3 {
		font-weight: 700;
		font-size: calc(55px + 54 * ((53vw + 53vh) - 520px) / 820);
		letter-spacing: -1px;
		color: rgba(255, 255, 255, 0);
		-webkit-text-stroke: 2px #fff;
	}

	.img-box {
		width: 40%;
		height: 100%;
		/* position: absolute; */
		transform: scale(0.6, 0.6);
		transition-duration: 1s;
		transition-property: transform;
		transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
		opacity: 0.9;
		overflow: hidden;
	}

	.img-box img {
		width: 100%;
		height: 100%;
		/* max-width: 29.0625rem; */
		object-fit: contain;
		transform: scale(1.2, 1.2) translateX(50%);
		transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
		transition-property: transform;
	}
	.button svg{
		width: 40px;
		/* background: rgba(0, 0, 0, .5); */
	}

	.button-prev,
	.button-next {
		transition: 0.5s;
		outline: none;
		position: absolute;
		width: 140px;
		z-index: 10;
		bottom: 50px;
		transform: translateY(-34px);
		cursor: pointer;
		color: red;
	}

	.button-prev {
		left: 4vw;
	}

	.button-next {
		right: 4vw;
	}

	.button.disabled {
		opacity: 0.2;
		cursor: default;
	}

	#arrow-svg-home {
		transform: translateY(353px);
	}

	.button-next #arrow-svg-home {
		transform: translateY(353px) rotateY(180deg);
		transform-origin: 80px 0px 0px;
	}

	svg {
		transition: 0.5s;
	}

	.cls-1 {
		transition: 0.5s;
		opacity: 0.4;
		transform-origin: -20px 40px;
		opacity: 1;
	}

	.cls-4 {
		transition: 0.5s;
		stroke-width: 2px;
		stroke: #fff;
		fill: none;
		stroke-dasharray: 1;
		stroke-dashoffset: 1;
		opacity: 0.4;
		transform-origin: 0px 0px 0px;
	}

	#arrow-trg {
		transition: 0.5s;
		fill: #fff;
		transform: rotateY(180deg) translate(-53px, 39px);
	}

	#line {
		transition: 0.5s;
		stroke: #fff;
		transform: translate(50px, 42px);
	}

	.button-prev:not(.disabled):hover svg {
		transform: translateX(-25px);
	}

	.button-next:not(.disabled):hover svg {
		transform: translateX(25px);
	}

	.button:not(.disabled):hover .cls-1 {
		transform: scale(1.1);
	}

	.button:not(.disabled):hover .cls-4 {
		stroke-dasharray: 2px;
		stroke-dashoffset: 2px;
		opacity: 1;
	}

	.button:not(.disabled):hover #arrow-trg {
		transform: rotateY(180deg) translate(-37px, 39px);
	}

	.button:not(.disabled):hover #line {
		transform: translate(35px, 42px) scaleX(0.33);
	}

	.btn {
		background: #13670b;
		width: max-content;
		padding: 15px 40px;
		font-weight: 700;
		cursor: pointer;
		border-radius: 2px;
		margin-top: 20px;
	}

	.btn:hover {
		background: #51a025;
		color: white;
	}

	.btn1 {
		background: #1b4677;
	}

	.btn1:hover {
		background: #2063b1;
		color: white;
	}
</style>
