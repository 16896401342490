<template>
	<div>
		<div class="swiper-container">
			<div class="swiper-wrapper">
				<div class="swiper-slide">
					<div class="title" data-swiper-parallax="-130%">
						<div class="titBox">
							<h4>
								NOW YOU CAN
							</h4>
							<h2 style="font-size: 50px;color: white;">
								Before Saving the Day
							</h2>
							<div class="btn">
								Discover more
							</div>
						</div>
					</div>
					  <div class="img-box">
					  	<img src="../assets/new/2.png">
					  	<div style="color: white;width: 500px;font-size: 18px;text-align: left;">
					  		Born in 1997, it is a mid-to-high-end draft beer brand under Zhujiang Beer. It is China's
					  		first real pure draft beer and one of the leaders and setters of pure draft beer standards
					  		in China.
					  		For a long time, Zhujiang Chunsheng has built its brand with an unreconciled brand attitude,
					  		fully implemented the brand concept of "Sheng·Extraordinary", and created draft beer
					  		products suitable for young consumers in order to bring consumers a better product
					  		experience. This is the original intention of Zhujiang Pure Life. Zhujiang Chunsheng adopts
					  		industry-leading low-temperature membrane filtration and sealed aseptic filling technology
					  		to preserve the freshness and nutrition of Zhujiang Chunsheng, bringing a smooth taste; and
					  		bringing young people’s unique taste buds experience and current trend aesthetics Combine,
					  		advocate breaking the convention, not reconciling to mediocrity, not sticking to the
					  		present.
					  	</div>
					  </div>
				</div>
				<div class="swiper-slide">
					<div class="title" data-swiper-parallax="-130%">
						<div class="titBox">
							<h4>
								For a Fresher World
							</h4>
							<h2 style="font-size: 50px;color: white;">
								The Closer
							</h2>

							<div class="btn">
								Discover more
							</div>
						</div>
					</div>
					<div class="img-box">
						<div>
							<img src="../assets/new/1.png" style="transform:translateX(0);width: 400px;height: unset;">
						</div>
						<div style="color: white;width: 500px;font-size: 18px;text-align: left;">
							Twenty years of beer brewing experience and unique brewing technology, absorbing
							international advanced beer brewing technology, created the Pearl River classic beer. It is
							carefully brewed with high-quality imported malt, hops and pearl beer yeast, which is a
							classic of Pearl River Beer. It has a full-bodied taste, rich aroma, moderate bitterness and
							endless aftertaste.
							<br>
							<br>
							<br>
							12°Lao Zhujiang inherits the brewing skills of Zhujiang Beer for many years. The rich malt
							aroma and long and full taste have become a classic product in the streets of Laoguang. To
							pay tribute to the classics, the 12°Lao Zhujiang condenses the fond memories of Lao Guang.
						</div>
					</div>
					
				</div>
				
				<div class="swiper-slide tiger">
					<div class="title" data-swiper-parallax="-130%">
					</div>
					  <div class="img-box">
					  	<img style="width: 500px;" src="../assets/wine/1.png">
					  	<div style="color: white;width: 500px;font-size: 18px;text-align: left;padding-left:100px;">
							<h2>
								品味·舍得
							</h2>
							<p>
								浓香带陈，香如芝兰，丰满细腻，绵甜爽口
							</p>
					  	</div>
					  </div>
				</div>
				<div class="swiper-slide tzh">
						<div class="title" data-swiper-parallax="-130%">
						</div>
						<div class="img-box">
							<div style="color: white;width: 500px;font-size: 18px;text-align: left;padding-top: 50px;">
								<h2>
									吞饮天地 酱香圣品
								</h2>
								<p>
									吞之乎，是一种豪迈的英雄气度。曹操曰：『夫英雄者，胸怀大志，腹有良谋，有包藏宇宙之机，吞吐天地之志者也。』讲的即是一种气吞山河的霸气与胸怀。凝聚东方智慧，传承千载酒文化，诠释智者的取舍之道，堪为高尚智慧人士的犒赏。
								</p>
							</div>
							<div>
								<img  src="../assets/wine/2.png" style="transform:translateX(0);width: 600px;height: unset;">
							</div>
						</div>
				</div>

			</div>
			<div class="button-prev button"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 350 160 90">
					<g id="arrow-svg-home">
						<g id="circ" class="cls-1">
							<circle cx="42" cy="42" r="40" class="cls-4"></circle>
						</g>
						<g id="arrow">
							<path id="arrow-trg" d="M.983,6.929,4.447,3.464.983,0,0,.983,2.482,3.464,0,5.946Z"></path>
						</g>
						<path id="line" d="M120,0H0" class="cls-3"></path>
					</g>
				</svg></div>
			<!--左箭头-->
			<div class="button-next button"> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 350 160 90">
					<g id="arrow-svg-home">
						<g id="circ" class="cls-1">
							<circle cx="42" cy="42" r="40" class="cls-4"></circle>
						</g>
						<g id="arrow">
							<path id="arrow-trg" d="M.983,6.929,4.447,3.464.983,0,0,.983,2.482,3.464,0,5.946Z"
								class="cls-2"></path>
						</g>
						<path id="line" d="M120,0H0" class="cls-3"></path>
					</g>
				</svg></div>
			<!--右箭头-->
		</div>
	</div>
</template>

<script>
	export default {
		name: 'homeBanner',
		data() {
			return {}
		},
		mounted() {
			// setTimeout(() => {
			this.swiperInit()
			// }, 1000)
		},
		methods: {
			swiperInit() {
				var slides = [],
					imgBox,
					imgPrev,
					imgActive,
					derection;
				var lock = false;
				var bgColor = ["rgb(13 56 32)","rgb(110 158 36)"
				]; //背景色
				var mySwiper = new Swiper('.swiper-container', {
					speed: 1300,
					autoplay: true,
					allowTouchMove: true, //禁止触摸滑动
					parallax: true, //文字位移视差
					on: {
						transitionStart: function() {
							lock = true; //锁住按钮
							slides = this.slides
							imgBox = slides.eq(this.previousIndex).find('.img-box') //图片包装器
							imgPrev = slides.eq(this.previousIndex).find('img') //当前图片
							imgActive = slides.eq(this.activeIndex).find('img') //下一张图片
							derection = this.activeIndex - this.previousIndex
							this.$el.css("background-color", bgColor[this.activeIndex]); //背景颜色动画

							imgBox.transform('matrix(0.6, 0, 0, 0.6, 0, 0)');
							imgPrev.transition(1000).transform('matrix(1.2, 0, 0, 1.2, 0, 0)'); //图片缩放视差
							this.slides.eq(this.previousIndex).find('h3').transition(1000).css('color',
								'rgba(255,255,255,0)'); //文字透明动画

							imgPrev.transitionEnd(function() {
								imgActive.transition(1300).transform(
									'translate3d(0, 0, 0) matrix(1.2, 0, 0, 1.2, 0, 0)'); //图片位移视差
								imgPrev.transition(1300).transform('translate3d(' + 60 * derection +
									'%, 0, 0)  matrix(1.2, 0, 0, 1.2, 0, 0)');
							});
						},
						transitionEnd: function() {
							this.slides.eq(this.activeIndex).find('.img-box').transform(
								' matrix(1, 0, 0, 1, 0, 0)');
							imgActive = this.slides.eq(this.activeIndex).find('img')
							imgActive.transition(1000).transform(' matrix(1, 0, 0, 1, 0, 0)');
							this.slides.eq(this.activeIndex).find('h3').transition(1000).css('color',
								'rgba(255,255,255,1)');

							imgActive.transitionEnd(function() {
								lock = false
							});
							//第一个和最后一个，禁止按钮
							if (this.activeIndex == 0) {
								this.$el.find('.button-prev').addClass('disabled');
							} else {
								this.$el.find('.button-prev').removeClass('disabled');
							}

							if (this.activeIndex == this.slides.length - 1) {
								this.$el.find('.button-next').addClass('disabled');
							} else {
								this.$el.find('.button-next').removeClass('disabled');
							}
						},
						init: function() {
							this.emit('transitionEnd'); //在初始化时触发一次transitionEnd事件
						},

					}
				});

				//不使用自带的按钮组件，使用lock控制按钮锁定时间
				mySwiper.$el.find('.button-next').on('click', function() {
					if (!lock) {
						mySwiper.slideNext();
					}
				})
				mySwiper.$el.find('.button-prev').on('click', function() {
					if (!lock) {
						mySwiper.slidePrev();
					}
				})
			}
		}
	}
</script>

<style scoped>
	.swiper-container {
		width: 100%;
		height: 100vh;
		background-color: rgb(13 56 32);
		transition: 1s background-color 1.3s;
	}
	.tzh{
		/* background: url('../assets/wine/bbg.png'); */
		background: #282322;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
	}
	.tiger{
		
	}
	.tiger::before{
		content: "";
		   position: absolute;
		   background: rgba(0, 0, 0, .3);
		   
		   z-index: -1;
		   width: 100%;
		   height: 100%;
	}
	.tiger::after{
		 content: "";
		    position: absolute;
			background: url('../assets/wine/bbg1.png');
			background-size: 100% 100%;
			filter: blur(5px);
			background-repeat: no-repeat;
			background-position: center;
			z-index: -2;
			width: 100%;
			height: 100%;
	}

	.swiper-wrapper {
		transition-delay: 1s;
		transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
	}

	.swiper-slide {
		text-align: center;
		font-size: 18px;
		/* Center slide text vertically */
		display: -webkit-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		display: flex;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		-webkit-justify-content: center;
		justify-content: center;
		-webkit-box-align: center;
		-ms-flex-align: center;
		-webkit-align-items: center;
		align-items: center;
	}

	.title {
		color: white;
		height: 100%;
		justify-content: flex-start;
		width: 100%;
		transition-delay: 1s;
		z-index: 10;
		display: flex;
		align-items: flex-end;
		padding: 0 4.75rem;
		padding-bottom: 10%;
		text-align: left;
	}

	.titBox {
		width: 400px;
	}

	.title h3 {
		font-weight: 700;
		font-size: calc(55px + 54 * ((53vw + 53vh) - 520px) / 820);
		letter-spacing: -1px;
		color: rgba(255, 255, 255, 0);
		-webkit-text-stroke: 2px #fff;
	}

	.img-box {
		width: 100%;
		height: 100%;
		position: absolute;
		transform: scale(0.6, 0.6);
		transition-duration: 1s;
		transition-property: transform;
		transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
		opacity: 0.9;
		overflow: hidden;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.img-box img {
		width: 400px;
		object-fit: cover;
		transform: scale(1.2, 1.2) translateX(50%);
		transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
		transition-property: transform;
	}

	.button-prev,
	.button-next {
		transition: 0.5s;
		outline: none;
		position: absolute;
		width: 140px;
		z-index: 10;
		top: 60vh;
		transform: translateY(-34px);
		cursor: pointer;
	}

	.button-prev {
		left: 4vw;
	}

	.button-next {
		right: 4vw;
	}

	.button.disabled {
		opacity: 0.2;
		cursor: default;
	}

	#arrow-svg-home {
		transform: translateY(353px);
	}

	.button-next #arrow-svg-home {
		transform: translateY(353px) rotateY(180deg);
		transform-origin: 80px 0px 0px;
	}

	svg {
		transition: 0.5s;
	}

	.cls-1 {
		transition: 0.5s;
		opacity: 0.4;
		transform-origin: -20px 40px;
		opacity: 1;
	}

	.cls-4 {
		transition: 0.5s;
		stroke-width: 2px;
		stroke: #fff;
		fill: none;
		stroke-dasharray: 1;
		stroke-dashoffset: 1;
		opacity: 0.4;
		transform-origin: 0px 0px 0px;
	}

	#arrow-trg {
		transition: 0.5s;
		fill: #fff;
		transform: rotateY(180deg) translate(-53px, 39px);
	}

	#line {
		transition: 0.5s;
		stroke: #fff;
		transform: translate(50px, 42px);
	}

	.button-prev:not(.disabled):hover svg {
		transform: translateX(-25px);
	}

	.button-next:not(.disabled):hover svg {
		transform: translateX(25px);
	}

	.button:not(.disabled):hover .cls-1 {
		transform: scale(1.1);
	}

	.button:not(.disabled):hover .cls-4 {
		stroke-dasharray: 2px;
		stroke-dashoffset: 2px;
		opacity: 1;
	}

	.button:not(.disabled):hover #arrow-trg {
		transform: rotateY(180deg) translate(-37px, 39px);
	}

	.button:not(.disabled):hover #line {
		transform: translate(35px, 42px) scaleX(0.33);
	}

	.btn {
		background: #ffffff80;
		width: max-content;
		padding: 15px 40px;
		font-weight: 700;
		cursor: pointer;
		border-radius: 2px;
		margin-top: 20px;
	}

	.btn:hover {
		background: #fff;
		color: #646464;
	}
</style>
