<template>
	<div class="beerHome">
		<div style="font-size: 0;">
			<img style="width: 100%;" src="../assets/new/5.jpeg" alt="">
		</div>
		<div class="container">
			<div>
				<div class="center">
					<img class="wow fadeInUp" src="../assets/new/tt1.png" alt="">
				</div>
				<div class="flex">
					<div class="wow fadeInUp">
						<img src="../assets/new/2.png" alt="">
					</div>
					<div class="wow fadeInUp" style="font-size: 20px;width: 50%;color: white;">
						Born in 1997, it is a mid-to-high-end draft beer brand under Zhujiang Beer. It is China's first
						real pure draft beer and one of the leaders and setters of pure draft beer standards in China.
						For a long time, Zhujiang Chunsheng has built its brand with an unreconciled brand attitude,
						fully implemented the brand concept of "Sheng·Extraordinary", and created draft beer products
						suitable for young consumers in order to bring consumers a better product experience. This is
						the original intention of Zhujiang Pure Life. Zhujiang Chunsheng adopts industry-leading
						low-temperature membrane filtration and sealed aseptic filling technology to preserve the
						freshness and nutrition of Zhujiang Chunsheng, bringing a smooth taste; and bringing young
						people’s unique taste buds experience and current trend aesthetics Combine, advocate breaking
						the convention, not reconciling to mediocrity, not sticking to the present.
					</div>
				</div>
			</div>
			<div>
				<div class="center">
					<img class="wow fadeInUp" src="../assets/new/tt2.png" alt="">
				</div>
				<div class="flex">

					<div class="wow fadeInUp" style="font-size: 20px;width: 50%;color: white;">
						<div style="font-size: 30px;font-weight: bold;">
							Pearl River Draft Beer
						</div>
						<p style="font-weight: bold;">
							Adopting high technology of low-temperature membrane filtration and sterile packaging,
							succeeding to the process and product characteristics of Pearl River Beer, Pearl River Group
							Company has developed Pearl River Draft Beer as the latest beer in 1990s'brewing with high
							quality brewing water, rice and imported malt. Pearl River Draft Beer is filled into new bottles
							or cans in specially built clean room without pasteurization for the sake of mellowness and
							fresh flavor after the advanced membrane filtration system imported from Germany. It can
							maintain the greatest extent of nutrition and flavors in beer.
						</p>
					</div>
					<div class="wow fadeInUp">
						<img src="../assets/new/3.png" alt="">
					</div>
				</div>

			</div>
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	export default {
		name: 'beer',
		components: {}
	}
</script>
<style scoped>
	.beerHome {
		background: #0e5656 url(../assets/new/bg1.jpeg) no-repeat top center;

	}

	.container {
		padding: 50px 0;
		width: 85%;
		margin: auto;
	}

	.center {
		text-align: center;
	}

	.numr img {
		margin-left: -40px;
		margin-right: 20px;
	}

	.numl img {
		margin-right: -40px;
		margin-left: 20px;
	}
</style>
