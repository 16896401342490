<template>
	<div class="products">
		<div style="height: 90px;"></div>
		<!-- <div class=" wow fadeInUp" style="padding: 0 4rem;margin:60px auto;width: 1200px;">
			<p style="color:#999;font-weight: bold;font-size: 14px;">OUR PRODUCTS</p>
			<div style='color:#13670b;font-family:system-ui;font-weight:bold;font-size:40px'>
				There’s more behind the star
			</div>
			<p class="" style="color:#999;margin-top:30px">
				One of the most iconic beers in the world has got you covered no matter what your taste. From our
				flagship, to our draught, to our alcohol free TMA® 0.0. It’s a world of refreshment at your
				taste-buds. Come discover it!
			</p>
		</div> -->

		<div style="font-size: 0;">
			<img style="width: 100vw;" src="../assets/new/5.jpeg" alt="">
			<!-- <img style="width: 100vw;" src="../assets/wine/bbg.png" alt=""> -->
		</div>
		<div>
			<div class="flex lists wow fadeInUp" >
				<div class="list">
					<!-- <router-link to=""> -->
					<div style="position: absolute;width: 100%;height: 100%;">
						<img class="bg" src="../assets/images/pro/bg1.jpeg" alt="">
						<img class="pro" src="../assets/new/1.png" alt="">
						<p class="tit">
							<!-- TMA® Original -->
						</p>
					</div>

					<!-- </router-link> -->
				</div>
				<div class="list">
					<!-- <router-link to=""> -->
					<div style="position: absolute;width: 100%;height: 100%;">
						<img class="bg" src="../assets/images/pro/bg2.jpeg" alt="">
						<img class="pro" src="../assets/new/3.png" alt="">
						<p class="tit">
							<!-- TMA® 0.0 -->
						</p>
					</div>

					<!-- </router-link> -->
				</div>
				<div class="list">
					<!-- <router-link to=""> -->
					<div style="position: absolute;width: 100%;height: 100%;">
						<img class="bg" src="../assets/images/pro/bg3.jpeg" alt="">
						<img class="pro" src="../assets/new/6.png" alt="">
						<p class="tit">
							<!-- Light -->
						</p>
					</div>
				
					<!-- </router-link> -->
				</div>
				
				
				
				<div class="list">
					<div style="position: absolute;width: 100%;height: 100%;">
						<img class="bg" src="../assets/images/pro/bg3.jpeg" alt="">
						<img class="pro" src="../assets/wine/2.png" alt="">
						<p class="tit">
							<!-- Light -->
						</p>
					</div>
				</div>
				<div class="list">
					<div style="position: absolute;width: 100%;height: 100%;">
						<img class="bg" src="../assets/images/pro/bg1.jpeg" alt="">
						<img class="pro" src="../assets/wine/3.png" alt="">
						<p class="tit">
							<!-- The Can -->
						</p>
					</div>
				</div>
				<div class="list">
					<div style="position: absolute;width: 100%;height: 100%;">
						<img class="bg" src="../assets/images/pro/bg1.jpeg" alt="">
						<img class="pro" src="../assets/wine/6.png" alt="">
						<p class="tit">
							<!-- TMA Original Minis -->
						</p>
					</div>
				</div>
				
				
				
				
				
				<!-- <div class="list">
					<div style="position: absolute;width: 100%;height: 100%;">
						<img class="bg" src="../assets/images/pro/bg1.jpeg" alt="">
						<img class="pro" src="../assets/new/7.png" alt="">
						<p class="tit">
						</p>
					</div>
				</div>
				<div class="list">
					<div style="position: absolute;width: 100%;height: 100%;">
						<img class="bg" src="../assets/images/pro/bg4.jpeg" alt="">
						<img class="pro" src="../assets/new/2.png" alt="">
						<p class="tit">
						</p>
					</div>
				</div>
				<div class="list">
					<div style="position: absolute;width: 100%;height: 100%;">
						<img class="bg" src="../assets/images/pro/bg2.jpeg" alt="">
						<img class="pro" src="../assets/new/8.png" alt="">
						<p class="tit">
						</p>
					</div>
				</div> -->
				
			</div>
		</div>
		
		<!-- <div class="flex wow fadeInUp" data-wow-delay=".5s" style="padding: 0 4rem; max-width: 120rem;margin-bottom: 60px;margin-top: 60px;">
			<div style="height: 100%;width: 650px;height: 500px;">
				<img style="width: 100%;height: 100%;object-fit: cover;" src="../assets/images/pro/pro.jpeg" alt="">
			</div>
			<div class="" style="flex: 1;margin-left: 30px;">
				<h2 style="font-size: .875rem;color: #999;">
					TMA® HISTORY
				</h2>
				<h2 style='color: #13670b;font-family: "", "Main", "PT Sans", serif;font-size: 2.5rem;margin-bottom: 1.25rem;'>
					A perfectly brewed history.
				</h2>
				<p style="color: #999;font-size: 1.125rem;">
					The history of TMA® is the history of innovation in beer brewing. From 1873 when Gerard TMA set out to create the ultimate premium lager beer, to present day under the watchful eye of master brewer Willem Van Waesberghe. TMA’s® iconic taste comes from its simplicity, quality and dedication to premium malt ingredients: the perfect malted barley, hop extract and our own special TMA A-Yeast®.
				</p>
				<div class="btn">
					History
				</div>
			</div>
			
		</div> -->
	</div>
</template>

<script>
	// @ is an alias to /src

	export default {
		name: 'products',
		components: {}
	}
</script>
<style scoped>
	.lists {
		display: flex;
		flex-wrap: wrap;
		margin: 0 auto;
		padding: 0;
		max-width: 120rem;
	}

	.list {
		position: relative;
		width: 33.3333333333%;
		padding-bottom: 33.3333333333%;
		cursor: pointer;
	}

	.list .bg {
		width: 100%;
		height: 100%;
	}

	.list .pro {
		height: 70%;
		transition: transform .3s ease-in-out;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%) scale(1);
	}

	.list:hover .pro {
		transform: translate(-50%, -50%) scale(1.1);
	}

	.list .tit {
		position: absolute;
		margin-bottom: 0;
		left: 50%;
		bottom: 8%;
		color: #000;
		font-weight: bold;
		transform: translate(-50%);
		opacity: .3;
		width: 100%;
		text-align: center;
	}
	.btn {
		color: white;
		background: #13670b;
		width: max-content;
		padding: 15px 40px;
		font-weight: 700;
		cursor: pointer;
		border-radius: 2px;
		margin-top: 20px;
	}
	
	.btn:hover {
		background: #51a025;
		color: white;
	}
</style>
