<template>
	<div class="home">
		<homeBanner></homeBanner>
		<!-- <div style="padding: 0 4rem;">
			<div style="margin-top: 5rem;">
				<h1 class="storyTit wow fadeInUp">Our Story</h1>
			</div>
			<div class="flex story">
				<div class="history wow fadeInUp" style="grid-area:a">
					<router-link to="history">
						<img src="../assets/images/history.jpeg" alt="">
						<div class="cardContnt">
							<span class="cardbg"></span>
							<div class="cardCon">
								<h3 class=" "
									style="color:#ffffffb3;text-transform:uppercase;font-size: 14px;font-weight: bold;">
									Our History</h3>
								<h2 class="">Born in Amsterdam, raised by the world</h2>
								<p class="p" style="">A rich
									taste; an even richer history. Discover how we got to where we are today.</p>
							</div>
						</div>
					</router-link>
				</div>
				<div class="history wow fadeInUp" style="grid-area:b">
					<router-link to="history">
						<img src="../assets/images/img4.jpg" alt="">
						<div class="cardContnt">
							<span class="cardbg"></span>
							<div class="cardCon">
								<h3 class=" "
									style="color:#ffffffb3;text-transform:uppercase;font-size: 14px;font-weight: bold;">
									Sustainability</h3>
								<h2 class="">The best beer is brewed in a better world</h2>
								<p class="p" style="">
									Business has a big role to play in being a positive force for change. For us, this
									is what being a sustainable company is all about.
								</p>
							</div>
						</div>
					</router-link>
				</div>
			</div>
		</div> -->
		<div class="midBanner">
			<homemid></homemid>
		</div>
		<div class="flex wow fadeInUp" data-wow-delay=".5s" style="padding: 0 4rem; max-width: 120rem;justify-content: center;margin: auto;">
			<div style="height: 100%;width: 450px;height: 500px;">
				<img style="width: 100%;height: 100%;object-fit: cover;" src="../assets/wine/2.png" alt="">
			</div>
			<div class="" style="margin-left: 30px;">
				<h2 style="font-size: .875rem;color: #999;">
					吞饮天地、酱香圣品
				</h2>
				<h2 style='color: #13670b;font-family: "", "Main", "PT Sans", serif;font-size: 2.5rem;'>
					吞之乎酒【酱香型】
				</h2>
				<!-- <h2 style='color: #13670b;font-family: "", "Main", "PT Sans", serif;font-size: 2.5rem;margin-bottom: 1.25rem;'>
					Responsibility
				</h2> -->
				<p style="color: #999;font-size: 1.125rem;">
					酱香突出、幽雅细腻、醇厚圆润、味长净爽。
				</p>
				<!-- <div class="btn">
					Discover more
				</div> -->
			</div>
		</div>
		<div>
			
		</div>
		<div style="padding: 0 16rem;margin-top: 50px;margin-bottom: 5rem;">
			<h2 style="font-size: .875rem;color: #999;">
				CAMPAIGNS & SPONSORSHIPS
			</h2>
			<h2 style='color: #13670b;font-family: "", "Main", "PT Sans", serif;font-size: 2.5rem;margin-bottom: 1.25rem;'>
				The Magic of TMA®
			</h2>
			<p style="color: #999;font-size: 1.125rem;">
				Tapping from our rich history and proud heritage, our campaigns tell the developing tale of our brand. The TMA® voice is witty, intelligent and speaks in a refreshing tone. Our vision is positive and yes, a bit cheeky at times. One thing is for sure, each campaign is a 100% TMA®. Enjoy!
			</p>
		</div>
		
	</div>
</template>

<script>
	// @ is an alias to /src
	import homeBanner from '@/components/homeBanner.vue'
	import homemid from '@/components/homemidba.vue'

	export default {
		name: 'HomeView',
		components: {
			homeBanner,
			homemid
		}
	}
</script>
<style scoped>
	.storyTit {
		color: #13670b;
		font-weight: 700;
		font-family: "", "Main", "PT Sans", serif;
		font-size: 3.25rem;
	}

	.history {
		/* flex: 1; */
		position: relative;
		overflow: hidden;
		width: 100%;
		/* height: 100%; */
		padding-top: 56.25%;
		cursor: pointer;
	}

	.history::after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: transparent linear-gradient(0deg, #000 0%, rgba(0, 0, 0, .4) 100%) 0% 0% no-repeat padding-box;
		opacity: .4;
		transition: opacity .3s ease;
	}

	.history img {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		width: 100%;
		height: 100%;
		object-position: center;
		object-fit: cover;
		transform: translate3d(0px, 1px, 0px) scale(1.15);
		will-change: transform;
		transition: transform 0.8s cubic-bezier(0, 0, 0, 1) 0s;
	}

	.history:hover::after {
		opacity: 0;
	}

	.history:hover img {
		transform: translate3d(0px, 1px, 0px) scale(1.25);
	}

	.story {
		grid-gap: 1.25rem;
		grid-template: "a b"1fr/1fr 1fr;
		display: grid;
		overflow: hidden;
	}

	.cardContnt {
		position: absolute;
		left: 30px;
		bottom: 30px;
		width: 30rem;
		height: auto;
		display: flex;
		align-items: flex-end;
		justify-content: flex-start;
		color: white;
		font-weight: bold;
		z-index: 10;
		font-family: "", "Main", "PT Sans", serif;
	}

	.cardbg {
		background: transparent radial-gradient(closest-side at 50% 50%, #51a025 0%, #13670b 71%, #005d1f 100%) 0% 0% no-repeat padding-box;
		opacity: .7;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		top: 0;
		width: 80%;
		padding: 20px 32px;
	}

	.cardCon {
		display: flex;
		flex-direction: column;
		position: relative;
		width: 80%;
		padding: 20px 32px;
		transition: all .3s;
	}

	.p {
		height: 0;
		opacity: 0;
		overflow: hidden;
		transition: all .3s;
		font-size: 12px;
		color: #ffffffb3;

	}

	.history:hover .p {
		transition: all .3s;
		height: 40px;
		opacity: 1;
	}

	.midBanner {
		padding: 20px 4rem;
		padding-top: 50px;
		overflow: hidden;
	}
	.btn {
		color: white;
		background: #13670b;
		width: max-content;
		padding: 15px 40px;
		font-weight: 700;
		cursor: pointer;
		border-radius: 2px;
		margin-top: 20px;
	}
	
	.btn:hover {
		background: #51a025;
		color: white;
	}
</style>
