<template>
	<div class="beerHome">
		<div style="font-size: 0;">
			<img style="width: 100%;" src="../assets/wine/5.jpeg" alt="">
		</div>
		<div class="container">
			<div>
				<div class="center" style="font-size: 30px;color: ;">
					吞饮天地 酱香圣品
				</div>
				<div style="width: 500px;color: ;margin: auto;text-align: center;color: #999;">
					<p>
						吞之乎，是一种豪迈的英雄气度。曹操曰：『夫英雄者，胸怀大志，腹有良谋，有包藏宇宙之机，吞吐天地之志者也。』讲的即是一种气吞山河的霸气与胸怀。凝聚东方智慧，传承千载酒文化，诠释智者的取舍之道，堪为高尚智慧人士的犒赏。
					</p>
				</div>
				<div class="flex" style="justify-content: center;">
					<div class="wow fadeInUp" style="text-align: center;flex: 1;">
						<img style="width: 50%;" src="../assets/wine/2.png" alt="">
					</div>
					<div class="wow fadeInUp" style="font-size: 20px;width: 50%;color: ;flex: 1;">
						<div>
							吞之乎(酱香型白酒)—舍得酒业战略大单品
						</div>
						<div>
							白酒吞之乎作为酱香型白酒是由舍得酒业推出的一款核心战略大单品。甄选观音故里吞之乎窖池酿造。
							作为以“酱香型白酒”新品类推出的白酒，吞之乎以创新的酱香型白酒酿造技艺、独特的酱香型口感并甄选酿藏至20年以上基酒，成就了吞之乎稀缺的非凡品味。
						</div>
					</div>
				</div>
			</div>
			<div style="width: 1200px;margin: auto;">
				<div style="font-size: 30px;font-weight: 500;">
					吞之乎系列特色
				</div>
				<div class="flex" style="justify-content: c;">
					<div style="font-size: 0;">
						<img style="width: 600px;" src="../assets/wine/zh1.jpeg" alt="">
					</div>
					<div style="font-size: 25px;padding-left: 30px;width: 600px;box-sizing: border-box;">
						<div>
							二十年 生态酿造 · 优选六粮
						</div>
						<div style="color: #666;font-size: 18px;">
							吞之乎产自中国最大规模的优质白酒生态酿造区。
							<br />
							开创了全生态酿造模式，
							<br />
							系全国首家绿色生态酿酒基地，
							<br />
							优选六粮，酿造绿色、生态、安全的白酒。
						</div>
					</div>
				</div>
				<div>
					
				</div>
				<div class="flex">
					<div style="font-size: 25px;width: 600px;">
						<div>
							二十年 酱香计划·初心不改
						</div>
						<div style="color: #666;font-size: 18px;">
							打造吞之乎专属酿造车间，
							<br />
							集聚名酒酿造大师，
							<br />
							储备优质基酒数十万吨，二十年不计耗时，
							<br />
							不惜代价，成就完美陈香酒体。
						</div>
					</div>
					<div style="font-size: 0;">
						<img style="width: 600px;" src="../assets/wine/zh2.jpeg" alt="">
					</div>
					
				</div>
				
				<div class="flex">
					<div style="font-size: 0;">
						<img style="width: 600px;" src="../assets/wine/zh3.jpeg" alt="">
					</div>
					<div style="font-size: 25px;padding-left: 30px;">
						<div>
							二十年 成熟精进·不断创新
						</div>
						<div style="color: #666;font-size: 18px;">
							两代酿造大师团队传袭独特技艺，
							<br />
							纯粮半堆积，国际制曲标准，独创双曲发酵工艺，
							<br />
							坚守传统酱香酿造的“2987”工艺
							<br />
							两次投粮、九次蒸煮、八次发酵、七次蒸馏。
							<br />
							6项国家发明专利。
						</div>
					</div>
				</div>
				<div class="flex">
					<div style="font-size: 25px;;width: 600px;">
						<div>
							二十年 陈藏基酒· 成就完美酱香风格
						</div>
						<div style="color: #666;font-size: 18px;">
							百年紫砂陶坛藏20年优质基酒，
							<br />
							自然老熟，去燥留醇，
							<br />
							经层层甄选，得吞之乎陈香飘逸，
							<br />
							香韵雅致典型陈香风格。
						</div>
					</div>
					<div style="font-size: 0;">
						<img style="width: 600px;" src="../assets/wine/zh4.jpeg" alt="">
					</div>
					
				</div>
				
				

			</div>
			<div>
				
			</div>
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	export default {
		name: 'beer',
		components: {}
	}
</script>
<style scoped>
	.beerHome {
		background: #fff ;

	}

	.container {
		padding: 50px 0;
		width: 85%;
		margin: auto;
	}

	.center {
		text-align: center;
	}

	.numr img {
		margin-left: -40px;
		margin-right: 20px;
	}

	.numl img {
		margin-right: -40px;
		margin-left: 20px;
	}
</style>
