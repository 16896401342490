<template>
	<div>
		<div class="pubHeadBox" :class="{bgColor:addBg,hideHead:!showHead}">
			<div class="headBox">
				<a class="logo" href="/">
					<img src="../assets/logo.png" alt="">
				</a>
				<nav>
					<ul class="navs">
						<!-- <li class="nav">
							<router-link to="/story">
								Our story
							</router-link>
						</li> -->
						<li class="nav">
							<router-link to="/">
								Home
							</router-link>
						</li>
						<li class="nav">
							<router-link to="/about">
								About
							</router-link>
						</li>
						<li class="nav">
							<router-link to="/products">
								Our products
							</router-link>
						</li>
						<li class="nav">
							<router-link to="/beer">
								Beer
							</router-link>
						</li>
						<li class="nav">
							<router-link to="/dbeer">
								Draft Beer
							</router-link>
						</li>
						<li class="nav">
							<router-link to="/shede">
								舍得
							</router-link>
						</li>
						<li class="nav">
							<router-link to="/tunzhihu">
								吞之乎
							</router-link>
						</li>
						<!-- <li class="nav">
							<router-link to="/tunzhihu">
								吞之乎
							</router-link>
						</li> -->
						<li class="nav">
							<router-link to="/activity">
								往期活动
							</router-link>
						</li>
						<!-- <li class="nav">
							<router-link to="/sponsorships">
								Sponsorships
							</router-link>
						</li> -->
						
						<!-- <li class="nav">
							<router-link to="/responsibly">
								Enjoy responsibly
							</router-link>
						</li> -->
					</ul>
				</nav>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'pubHead',
		props: {
			msg: String
		},
		data() {
			return {
				addBg: false,
				bgArr: ['sponsorships', 'products'],
				// 滚动前，滚动条距文档顶部的距离
				oldScrollTop: 0,
				showHead:true
			}
		},
		watch: {
			$route(a, b) {
				window.scrollTo(0,0)
				if (this.bgArr.indexOf(a.name) >= 0) {
					this.addBg = true
				} else {
					this.addBg = false
				}
				console.log(a)
			}
		},
		mounted() {
			// 监听页面滚动事件
			window.addEventListener("scroll", this.scrolling)
		},
		created() {
			if (this.bgArr.indexOf(this.$route.name) >= 0) {
				this.addBg = true
			} else {
				this.addBg = false
			}
			console.log(this.$route)
		},
		methods: {
			scrolling() {
				// 滚动条距文档顶部的距离
				let scrollTop = window.pageYOffset || document.documentElement.scrollTop ||
					document.body.scrollTop
				// 滚动条滚动的距离
				let scrollStep = scrollTop - this.oldScrollTop;
				// 更新——滚动前，滚动条距文档顶部的距离
				this.oldScrollTop = scrollTop;
				console.log(scrollStep)
				if (scrollStep < 0) {
					// alert("滚动条向上滚动了！")
					this.showHead = true
				} else {
					this.showHead = false
					// alert("滚动条向下滚动了！")
				}
			}
		}
	}
</script>

<style scoped>
	.pubHeadBox {
		width: 100vw;
		background-color: #0009;
		background: #00000038;
		/* background: #2f2e35f2; */
		position: fixed;
		top: 0;
		z-index: 1000;
		backdrop-filter: blur(.125rem);
		transition: all .3s;
	}

	.bgColor {
		transition: all .3s;
		background: transparent linear-gradient(to right, #005d1f 0%, #277816 50%, #005d1f 100%) 0% 0% no-repeat padding-box;
	}

	.headBox {
		padding: 0 4rem;
		display: flex;
		align-items: center;
		justify-content: space-between;

	}

	.logo img {
		width: 5.25rem;
	}

	.navs {
		display: flex;
		align-items: center;
	}

	.nav {
		text-transform: uppercase;
		font-weight: 700;
		font-size: .875rem;
		padding: 3.3125rem 1.25rem 1.125rem;
		position: relative;
	}

	.nav a::after {
		position: absolute;
		content: '';
		width: 0%;
		bottom: 10px;
		height: 1px;
		background: white;
		left: 0;
		right: 0;
		margin: auto;
		transition: all .2s;
	}

	.nav:hover a::after {
		width: 50%;
		transition: all .2s;
	}
	.hideHead{
		transform: translateY(-100%);
		transition: all .3s;
	}
</style>
