<template>
	<div class="beerHome">
		<div style="font-size: 0;">
			<img src="../assets/new/4.jpeg" alt="">
		</div>
		<div class="container">
			<div>
				<div class="center">
					<img class="wow fadeInUp" src="../assets/new/t1.png" alt="">
				</div>
				<div class="flex">
					<div class="wow fadeInUp">
						<img src="../assets/new/1.png" alt="">
					</div>
					<div class="wow fadeInUp" style="font-size: 20px;width: 50%;">
						Twenty years of beer brewing experience and unique brewing technology, absorbing international
						advanced beer brewing technology, created the Pearl River classic beer. It is carefully brewed
						with high-quality imported malt, hops and pearl beer yeast, which is a classic of Pearl River
						Beer. It has a full-bodied taste, rich aroma, moderate bitterness and endless aftertaste.
						<br>
						<br>
						<br>
						12°Lao Zhujiang inherits the brewing skills of Zhujiang Beer for many years. The rich malt aroma
						and long and full taste have become a classic product in the streets of Laoguang. To pay tribute
						to the classics, the 12°Lao Zhujiang condenses the fond memories of Lao Guang.
					</div>
				</div>
			</div>
			<div>
				<div class="center">
					<img class="wow fadeInUp" src="../assets/new/t2.png" alt="">
				</div>
				<div class="flex" style="justify-content: center;position: relative;">
					<div style="height: 400px;display: flex;flex-direction: column;justify-content: space-between;">
						<div class="num flex numl wow fadeInUp">
							<div style="text-align: right;">
								<div style="font-size: 25px;font-weight: bold;">
									中国酿酒大师经典之作
								</div>
								<p style="color: #670203;">不可复制的味道</p>
							</div>
							<div>
								<img src="../assets/new/n1.png" alt="">
							</div>
						</div>
						<div class="num flex numl wow fadeInUp">
							<div style="text-align: right;">
								<div style="font-size: 25px;font-weight: bold;">
									优质原材料 成就好啤酒
								</div>
								<p style="color: #670203;">优质水源经层层净化，每一口都清冽爽口。浓郁麦香同时伴着清彻的芳香诱惑，醇厚饱满，回味悠长</p>
							</div>
							<div>
								<img src="../assets/new/n3.png" alt="">
							</div>
						</div>
					</div>
					<div class="wow fadeInUp" style="height: 900px;">
						<img src="../assets/new/8.png" alt="">
					</div>
					<div style="height: 400px;display: flex;flex-direction: column;justify-content: space-between;">
						<div class="num flex numr wow fadeInUp">
							<div>
								<img src="../assets/new/n2.png" alt="">
							</div>
							<div style="text-align: left;">
								<div style="font-size: 25px;font-weight: bold;">
									12°麦汁浓度
								</div>
								<p style="color: #670203;">高浓度啤酒，酿造周期长 稳定性好口感极佳</p>
							</div>

						</div>
						<div class="num flex numr wow fadeInUp">
							<div>
								<img src="../assets/new/n4.png" alt="">
							</div>
							<div style="text-align: left;">
								<div style="font-size: 25px;font-weight: bold;">
									匠心工艺 品质保障
								</div>
								<p style="color: #670203;">密封发酵，减少空气杂质影响，口感更佳清爽可口。慢工酿造，以打造高品质产品为宗旨，坚持多花50%时间，只为</p>
							</div>

						</div>
					</div>

				</div>
			</div>
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	export default {
		name: 'beer',
		components: {}
	}
</script>
<style scoped>
	.beerHome {
		background: url(../assets/new/bg.jpeg) no-repeat top center;
	}

	.container {
		padding: 50px 0;
		width: 85%;
		margin: auto;
	}

	.center {
		text-align: center;
	}

	.numr img {
		margin-left: -40px;
		margin-right: 20px;
	}

	.numl img {
		margin-right: -40px;
		margin-left: 20px;
	}
</style>
